import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    EditButton,
    Edit,
    SimpleForm,
    Create,
    TextInput,
    DateInput
} from 'react-admin';

export const CompanyList = (props) => (
    <List {...props} sort={{ field: 'Date', order: 'DESC' }}>
        <Datagrid>
            <TextField source="Title" />
            <TextField source="Organization" />
            <DateField locales="ja-JP" source="Date" />
            <UrlField source="URL" target="_blank" />
            <TextField source="Type" />
            <EditButton />
        </Datagrid>
    </List>
);

export const CompanyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="Title" />
            <TextInput source="Organization" />
            <DateInput locales="ja-JP" source="Date" />
            <TextInput source="URL" />
            <TextInput source="Type" />
        </SimpleForm>
    </Edit>
);

export const CompanyCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="Title" />
            <TextInput source="Organization" />
            <DateInput locales="ja-JP" source="Date" />
            <TextInput source="URL" />
            <TextInput source="Type" />
        </SimpleForm>
    </Create>
);
