import About from './about';
import Table from './Table';
import Testimonials from './testimonials';
import Kosher from './kosher';
import Companies from './companies.jsx';
import OU from './ou';
import KLBD from './klbd';
import FAQ from './faq';
import Contact from './contact';
import CompanyTabs from './tabs';

const Main = () => {
    return (
        <div>
            <div className="container ">
                <div className="section-title">
                    <h1>ヤマミズラ 日本でコーシャ認定取得</h1>
                </div>
            </div>
            <About />
            <div className="container">
                <CompanyTabs />
                <Table
                    className="row"
                    dataType="news"
                    headerText="What is new?"
                />
            </div>
            <Testimonials />
            <Kosher />
            <Companies />
            <OU />
            <KLBD />
            <FAQ />
            <Contact />
        </div>
    );
};

export default Main;
