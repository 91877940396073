const KLBD = () => {
    return (
        <div>
            <section className="about-lists">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            KLBD(Kosher Division of London Beth Din) シンボル
                        </h2>
                    </div>
                    <div className="container">
                        <div className="row no-gutters">
                            <div className=" col-md-6 content-item">
                                <h4>有名ブランド企業</h4>
                                <p>
                                    Akzo, Cargil, Dow Chemical, Mars, Twining,
                                    Basf, Kikkoman, Nissin Oilio, Nescafe,
                                    Johnson Matthay...
                                </p>
                            </div>

                            <div className=" col-md-6 content-item">
                                <h4>主な日本企業</h4>
                                <p>
                                    江崎グリコ、FGAラボラトリー、大関、加藤吉平商店、キッコーマン.ヨーロッパ、サントリー.ヨーロッパ、四国明治飲料、鈴江コーポ、玉の光酒造、世嬉の一酒造、ナイカイ塩業、ニュートリー、日清オイリオ、日本精化、富士化学工業、北條製餡所、宝来屋本店、三井製糖、三井物産ベネルックス、山田醸造、他。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="counts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>企業の国別</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>70</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>EUでマーケッシェア(％)</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>45</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>認証企業数</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>2000</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>認定商品数</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>50000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default KLBD;
