const authProvider = {
    // authentication
    login: (params) => {
        const { username, password } = params;

        const request = new Request(process.env.REACT_APP_BASE_URL + '/login', {
            method: 'POST',
            body: JSON.stringify({ username: username, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((auth) => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            // localStorage.removeItem('auth');
            return Promise.reject({ redirectTo: '/admin' });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: (params) =>
        localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject({ message: 'Login Required' }),
    logout: () => {
        if (localStorage.getItem('auth')) {
            const { token } = JSON.parse(localStorage.getItem('auth'));
            const request = new Request(
                process.env.REACT_APP_BASE_URL + '/logout',
                {
                    method: 'POST',
                    headers: new Headers({ Authorization: 'Bearer ' + token })
                }
            );
            return fetch(request).then(() => {
                localStorage.removeItem('auth');
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    },
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: (params) => Promise.resolve()
};

export default authProvider;
