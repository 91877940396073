import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from './Table';

function CompanyTabs() {
    return (
        <Tabs
            defaultActiveKey="klbd"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="klbd" title="KLBD">
                <Table
                    className="row"
                    dataType="klbd"
                    headerText="Newly Certified KLBD Companies"
                />
            </Tab>
            <Tab eventKey="ou" title="OU">
                <Table
                    className="row"
                    dataType="ou"
                    headerText="Newly Certified OU Companies"
                />
            </Tab>
        </Tabs>
    );
}

export default CompanyTabs;
