const Companies = () => {
    return (
        <section id="companies" className="about-lists section-bg">
            <div className="container">
                <div className="section-title">
                    <h2>Global Kosher 市場</h2>
                </div>
                <div className="container">
                    <div className="row no-gutters">
                        <div className=" col-md-6 content-item">
                            <h4>ご存知でしたか？</h4>
                            <p>
                                コーシャマークは安心安全、クリーンで祝福された食品のシンボルマークとして、又、アレルギー源の見極めのシンボルとして消費者から信頼される絶対信頼マークです。
                            </p>
                        </div>

                        <div className=" col-md-6 content-item">
                            <h4>市場での特徴</h4>

                            <ul>
                                <li>
                                    <p align="left">
                                        世界の大手食品メーカーの新商品は100％コーシャ対応。
                                    </p>
                                </li>
                                <li>
                                    <p align="left">
                                        全世界のKosherのみを食する消費者市場規模は3兆円で毎年20％伸びを予想。
                                    </p>
                                </li>
                                <li>
                                    <p align="left">
                                        アメリカでは食品棚に並ぶ50％以上にOU,KLBD,OKなどトップ認定団体のマーク入り。
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className=" col-md-6 content-item">
                            <h4>信頼を得てブランドの”差別化”を</h4>
                            <p>
                                一見なじみのないユダヤの食文化ですが、コーシャは国際市場で急速に認知度を高めています。「コーシャ食品は原料が厳格だから、安全で高品質」という信頼性が浸透して、欧米を中心に、健康で純正な食品として注目されています。コーシャの信頼は製品の”差別化”へつながり、大きなビジネスチャンスへとつながります。
                            </p>
                        </div>

                        <div className=" col-md-6 content-item">
                            <h4>コーシャ認証の世界的権威、OUとKLBD</h4>

                            <ul>
                                <li>
                                    <p align="left">
                                        OKを含めて、歴史、規模、権威の総合面からコーシャ認証のTop
                                        Threeとよばれている。
                                    </p>
                                </li>
                                <li>
                                    <p align="left">
                                        イスラエル初めどこの国でも広くコーシャマークとして通用。
                                    </p>
                                </li>
                                <li>
                                    <p align="left">
                                        認定団体間での相互尊重の合意あり。
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Companies;
