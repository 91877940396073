const OU = () => {
    return (
        <div>
            <section className="about-lists">
                <div className="container">
                    <div className="section-title">
                        <h2>Orthodox Union (OU) シンボル</h2>
                    </div>
                    <div className="container">
                        <div className="row no-gutters">
                            <div className=" col-md-6 content-item">
                                <h4>有名ブランド企業</h4>
                                <p>
                                    ADM, Avebe, Cargill, Coca Cola, Peter
                                    Cremer, Danisco, DSM, Heinz,　McCormick,
                                    Nestle, Proctor & Camble, Unilever...
                                </p>
                            </div>

                            <div className=" col-md-6 content-item">
                                <h4>主な日本企業</h4>
                                <p>
                                    味の素グループ、池田製茶、伊藤園グループ、MTアクアポリマー、キッコーマンアメリカ、京屋酒造、菊水酒造、静パック、白形伝四郎商店、タカラ酒造、合同酒精、ニッスイグループ、ハラダ製茶、丸山製茶、ミツカン、明治製菓、ヤクルト薬品工業、ヤマサ、レキオスジャパン、他
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="counts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>企業の国別</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>103</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>USAでマーケッシェア(%)</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>70</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>認証企業数</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>5500</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="count-box">
                                <div
                                    className="section-title"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <h3>認定商品数</h3>
                                </div>
                                <p style={{ fontSize: '20px' }}>1000000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OU;
