const About = () => {
    return (
        <section id="about" className="about">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
                        <div className="section-title">
                            <h2>Yama Mizrachについて</h2>
                            <p>
                                <strong>設立：2011年8月</strong>
                            </p>
                            <p>
                                私たちヤマミズラは、食品や食品原材料、化学原料、薬品・化粧品等の企業の皆様が世界で通用するコーシャ(kosher)認証の取得を全面的にサポートしています。これまで百社近い日本企業がOU(Orthodox
                                Union)やKLBD等のスーパーコーシャ団体より認証取得されています。全世界で十二分に通用する団体の認証取得を行うこと、それが貴社の信頼の証につながります。
                            </p>
                            <p>
                                日本企業のための食品、製薬等分野での“コーシャ認証”の取得コンサル業務。現在まで100
                                社以上の企業への取得サポート実績あり。90工場を認定。
                                認証後の市場売り先紹介など。（展示会アテンド、安倍首相中東訪問団、農林省、JETROエベント、TV等媒体を通じてのサポート実績多数あり）
                            </p>
                        </div>

                        <div className="icon-box">
                            <div className="icon">
                                <img
                                    src="assets/img/KLBD_mark.gif"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <h4>ヨーロッパ最大のコーシャ認定団体</h4>
                            <h4>KLBD日本事務所</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
                        <div className="section-title">
                            <h2>主なコーシャ認証取得企業</h2>
                            <p>
                                秋田酒類製造、池田製茶、伊藤園グループ、魚沼醸造、Ferme
                                due Soleiu,
                                FGAラボラトリーズ、江崎グリコ、大関、加藤化学、加藤吉兵商店、カプスゲルジャパン、菊水酒造、きちみ製麺、京屋酒造、九重味醂、Kemira
                                (Finland),
                                櫻井食品、サンエイ糖化、盛香堂石田、関谷醸造、JA市川、JF愛南漁業協同組合,四国明治飲料、白瀧酒造、佐々木製茶、鈴江、世嬉乃一酒造、杉本商店、タカショク、田中酒造、玉乃光酒造、東亜食品、当栄ケミカル、長岡香料、中六、ナイカイ塩業、新澤酒造、日本精化、ニュートリー、ハラダ製茶、富士化学工業、福島醤油組合、北条製餡所、宝来屋本店、MT
                                アクアポリマー、森永乳業、J
                                オイルミルズ、丸山製茶、三井製糖、三菱ケミカル、ヤクルト薬品工業、やまま満寿田園、ユナイテッドフーズインターナショナル他。（敬称略）
                            </p>
                        </div>

                        <div className="icon-box">
                            <div className="icon">
                                <img
                                    src="assets/img/OU_mark.gif"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <h4>世界最大のコーシャ認定団体</h4>
                            <h4>OU(Orthodox Union)日本事務所</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
