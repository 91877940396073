const Header = () => {
    return (
        <header
            id="header"
            className="d-flex align-items-center"
            style={{ position: 'sticky', top: '0' }}
        >
            <div className="container d-flex align-items-center">
                <div className="logo me-auto">
                    <h1>
                        <a href="/">Yama Mizrach</a>
                    </h1>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li>
                            <a
                                className="nav-link scrollto active"
                                href="#hero"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#about">
                                Yama Mizrachについて
                            </a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#kosher">
                                コーシャとは
                            </a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#companies">
                                市場
                            </a>
                        </li>
                        <li>
                            <a
                                className="nav-link scrollto"
                                href="#acquisition"
                            >
                                取得方法
                            </a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#contact">
                                お問い合わせ
                            </a>
                        </li>
                        <li>
                            <a
                                className="nav-link scrollto"
                                href="acquisition.html"
                            >
                                お申し込み用紙
                            </a>
                        </li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>
            </div>
        </header>
    );
};

export default Header;
