import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Edit,
    SimpleForm,
    Create,
    TextInput,
    DateInput
} from 'react-admin';

export const NewsList = (props) => (
    <List {...props} sort={{ field: 'Date', order: 'DESC' }}>
        <Datagrid>
            <TextField source="Title" />
            <TextField source="Description" />
            <DateField locales="ja-JP" source="Date" />
            <EditButton />
        </Datagrid>
    </List>
);

export const NewsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="Title" />
            <TextInput source="Description" />
            <DateInput locales="ja-JP" source="Date" />
        </SimpleForm>
    </Edit>
);

export const NewsCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="Title" />
            <TextInput source="Description" />
            <DateInput locales="ja-JP" source="Date" />
        </SimpleForm>
    </Create>
);
