const Testimonials = () => {
    return (
        <section className="testimonials section-bg">
            <div className="container">
                <h2>お客様の声</h2>
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className="profile">
                            <img
                                src="assets/img/houraiyalogo.png"
                                className="user"
                                alt=""
                            />
                            <blockquote>
                                コーシャ認証取得までの詳細なやり取りだけでなく、取得以降のフォローも大変お世話になっております。毎年の更新作業は勿論の事、コーシャ認証商品を希望しているお取引先様をご紹介いただいております。弊社はヤマミズラ様のご紹介によりイスラエルへの輸出に成功し、現在はドバイ向けにもチャレンジしております。認証の取得で満足するのではなく、コーシャ認証を活用した実績に繋がるサポートに大変感謝しております。
                            </blockquote>
                            <h3>宝来屋</h3>
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <div className="profile">
                            <img
                                src="assets/img/empty.png"
                                className="user"
                                alt=""
                            />
                            <blockquote>
                                「世界中の人々に“美味しい本物の日本の麺”を食べていただきたい。」
                                米国のメインストリームへアプローチするにはKosherマークは必要不可欠だと感じOUのKosher認証を取得を決めました。Kosher
                                についての知識に乏しく、英語の申請書類を前に困惑しているときにヤマミズラを知り、Kosher全般の概容やKosher申請書類作成の要点、原材料のKosher適性、製造ラインにおける審査機関の要求事項などについて丁寧かつ的確なアドバイスをいただき、英語が不得意な私どもでもOUのKosher認証を取得することができました。その後多くの商談要請が舞い込み輸出拡大に大いに期待しているところです。
                                　Kosher認証取得を検討されている食品企業の方は、気軽にヤマミズラに相談することをお勧めします.
                            </blockquote>
                            <h3>東亜食品工業株式会社</h3>
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <div className="profile">
                            <img
                                src="assets/img/shikoku-logo.png"
                                className="user"
                                alt=""
                            />
                            <blockquote>
                                Kosherの認定を取得しようと思いたち、取引先の香料会社様がすでにKosher認証を、ヤマミズラを通して取得されていた関係でヤマミズラを紹介いただきました。社内での認証取得への反対ありうまく前に進まない中、ヤマミズラのサポート資料、事前の現場チェックなどを通じて無事取得できることが出来ました。他の認証団体などは、取得すれば関係性が途切れる場合が多々あると聞いたりしていましたが、取得した後のサポートがしっかりしています。Kosher商品を求めている顧客の紹介、海外の商談や認定審査の際などに通訳サポートも含めてサポートくださっています。ヤマミズラの紹介で国内のKosher取得した会社様との情報交換、横の繋がりができたことも満足。Kosherってなに？Kosher考えようか？Kosher取得してみようか？と聞かれたら即、ヤマミズラをお勧めします。間違いなく。弊社は、このヤマミズラを選んで大変感謝、納得しています。
                            </blockquote>
                            <h3>四国明治飲料有限会社</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
