import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import { NewsList, NewsEdit, NewsCreate } from '../components/newss.js';
import {
    CompanyList,
    CompanyEdit,
    CompanyCreate
} from '../components/companies.js';

import BusinessIcon from '@material-ui/icons/Business';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import Dashboard from '../components/dashboard';
import authProvider from '../components/authProvider';

const httpClient = (url, options = {}) => {
    const { token } = JSON.parse(localStorage.getItem('auth'));

    options.user = {
        authenticated: true,
        token: `Bearer ${token}`
    };
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
    process.env.REACT_APP_BASE_URL,
    httpClient
);

const AdminPage = () => {
    return (
        <Admin
            dashboard={Dashboard}
            authProvider={authProvider}
            dataProvider={dataProvider}
        >
            <Resource
                name="admin/news"
                list={NewsList}
                edit={NewsEdit}
                create={NewsCreate}
                icon={FiberNewIcon}
                options={{ label: 'News' }}
            />
            <Resource
                name="admin/company"
                list={CompanyList}
                edit={CompanyEdit}
                create={CompanyCreate}
                icon={BusinessIcon}
                options={{ label: 'Company' }}
            />
        </Admin>
    );
};

export default AdminPage;
