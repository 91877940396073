const Kosher = () => {
    return (
        <section id="kosher" className="services">
            <div className="container">
                <div className="section-title">
                    <h2>コーシャとは</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 icon-box">
                        <h4 className="title">コーシャ食品をご存じですか？</h4>
                        <p className="description">
                            もともとは、紀元前の旧約聖書の教えに即した、カシュルートと呼ばれる厳格な「食事規定」がその語源。「コーシャ」とはこの食事規定で「食べて良い食物」のことを意味します。
                        </p>
                        <p className="description">
                            製品の原材料から製造過程まで厳しくチェックされるコーシャ。その判断基準が細かく厳格がゆえに、品質の安全性に信頼が出来るのです。
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 icon-box">
                        <h4 className="title">
                            食べてはいけない肉類の条件は？
                        </h4>
                        <p className="description">
                            コーシャで食べて良い肉類は、基本的に草食動物であり且つ反芻動物（胃を二つ以上持つ動物）であることが条件になります。牛、羊・・・・○　豚、ウサギ・・・・×）
                        </p>
                        <p className="description">
                            さらに、完全に血抜きされた一定の食肉処理を施された肉のみ食することが出来ます。肉の血液は悪い菌を体内に運ぶと言われており、衛生上理にかなった規定といえます。
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box">
                        <h4 className="title">魚介類は食べてもいいの？</h4>
                        <p className="description">
                            海や川・湖に住む生き物で、ヒレやウロコのあるものは食べても良いとされていますが、エビや蟹などの甲殻類貝類・たこ・イカなどは食べられません。
                        </p>
                        <p className="description">
                            ウロコが目立たないウナギも食べられません。
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box">
                        <h4 className="title">
                            コーシャってどうしてそんなに厳しいの？
                        </h4>
                        <p className="description">
                            牛肉と乳製品の”食べ合わせ”を禁じたり、動物性油を禁じたり調理器具や製造過程にも厳しい規定があります。それは厳しい砂漠地帯を生き抜く民族の知恵だったのかもしれません。
                        </p>
                        <p className="description">
                            現代の食生活にも誠実に古の知恵を守るコーシャだからこそ衛生的で、健康的な食品として世界から信頼を得ているのです。
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box">
                        <h4 className="title">
                            どのような製品がコーシャ認定を必要とするの？
                        </h4>
                        <p className="description">
                            コーシャはあらゆる分野で必要とされています。
                        </p>
                        <ul>
                            <li>
                                食品全般とその他の関連品（魚、スナック、オーブン料理など）
                            </li>
                            <li>
                                食品工場での原材料全ての食用油、大豆に由来する全ての商品、着色料を含む食品など）
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box">
                        <h4 className="title">
                            どのような製品がコーシャ認定を必要とするの？
                        </h4>
                        <ul>
                            <li>
                                製薬、医療関係（ビタミン剤、ホメオパシー薬、薬局販売の医療品など）
                            </li>
                            <li>
                                <p align="left">
                                    ベビーフード関係（ミルク代用品など）
                                </p>
                            </li>
                            <li>
                                <p align="left">
                                    化粧品など、衛生用品、洗剤、掃除用品
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Kosher;
