import React from 'react';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator_modern.min.css'; // theme
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap4.min.css'; // theme

import { ReactTabulator } from 'react-tabulator';
import moment from 'moment';
import { DateTime } from 'luxon';
window.moment = moment;
window.DateTime = DateTime;

class Table extends React.Component {
    state = {
        data: [],
        columns: []
    };
    ref = null;

    componentDidMount() {
        let dataType = 'news';
        if (this.props.dataType === 'klbd' || this.props.dataType === 'ou') {
            dataType = 'company';
        }
        fetch(`${process.env.REACT_APP_BASE_URL}/${dataType}/all`, {
            mode: 'cors'
        })
            .then((response) => response.json())
            .then((items) => {
                let data = items[dataType];
                let columns = [
                    {
                        title: '日付',
                        field: 'Date',
                        sorter: 'date',
                        sorterParams: {
                            format: 'yyyy-MM-dd',
                            alignEmptyValues: 'top'
                        },
                        widthGrow: 1
                    },
                    {
                        title: '会社',
                        field: 'Title',
                        sorter: 'string',
                        widthGrow: 2
                    },
                    {
                        title: 'ウェブサイト',
                        field: 'URL',
                        sorter: 'string',
                        formatter: 'link',
                        formatterParams: {
                            urlPrefix: 'http://',
                            target: '_blank'
                        },
                        widthGrow: 2
                    },
                    {
                        title: '製品',
                        field: 'Type',
                        sorter: 'string',
                        formatter: 'textarea',
                        widthGrow: 1
                    }
                ];

                if (this.props.dataType === 'klbd') {
                    data = data.filter((x) => x.Organization === 'KLBD');
                } else if (this.props.dataType === 'ou') {
                    data = data.filter((x) => x.Organization === 'OU');
                } else {
                    columns = [
                        {
                            title: '日付',
                            field: 'Date',
                            sorter: 'date',
                            sorterParams: {
                                format: 'yyyy-MM-dd',
                                alignEmptyValues: 'top'
                            },
                            widthGrow: 1
                        },
                        {
                            title: '概要',
                            field: 'Title',
                            sorter: 'string',
                            formatter: 'textarea',
                            widthGrow: 3
                        },
                        {
                            title: '詳細',
                            field: 'Description',
                            sorter: 'string',
                            formatter: 'textarea',
                            widthGrow: 3
                        }
                    ];
                }

                this.setState({
                    columns: columns,
                    data: data
                });
            })
            .catch((err) => console.log(err));
    }

    render() {
        const options = {
            tooltips: true,
            layout: 'fitColumns',
            pagination: 'local',
            paginationSize: 5,
            paginationSizeSelector: [5, 10, 20],
            resizableRows: true,
            initialSort: [
                { column: 'Date', dir: 'desc' } //sort by this first
            ]
        };
        return (
            <div>
                <h4 className="title">{this.props.headerText}</h4>
                <ReactTabulator
                    ref={(ref) => (this.ref = ref)}
                    data={this.state.data}
                    options={options}
                    columns={this.state.columns}
                />
            </div>
        );
    }
}

export default Table;
