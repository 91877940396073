const Contact = () => {
    return (
        <section id="contact" className="contact section-bg">
            <div className="container">
                <div className="section-title">
                    <h2>お問い合わせ</h2>
                </div>

                <div className="row">
                    <div className="col-lg-6 d-flex">
                        <div className="info-box">
                            <i className="bx bx-map"></i>
                            <h3>住所</h3>
                            <p>
                                〒980-0804 仙台市青葉区大町1丁目1-8
                                第三青葉ビル4F
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 d-flex">
                        <div className="info-box">
                            <i className="bx bx-envelope"></i>
                            <h3>Email</h3>
                            <a href="mailto:info@yamamizrach.com">
                                info@yamamizrach.com
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 d-flex">
                        <div className="info-box ">
                            <i className="bx bx-phone-call"></i>
                            <h3>連絡先</h3>
                            <p>+81 022-221-3568</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
