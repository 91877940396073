import { BrowserRouter as Router, Route } from 'react-router-dom';
import AdminPage from './pages/admin.jsx';
import Home from './pages/home.jsx';

const App = () => (
    <Router>
        <Route exact path="/">
            <Home />
        </Route>
        <Route exact path="/admin">
            <AdminPage />
        </Route>
    </Router>
);

export default App;
