const FAQ = () => {
    return (
        <section id="faq" className="faq">
            <div className="container">
                <div className="section-title">
                    <h2>認証団体についてよくあるご質問。</h2>
                </div>

                <div className="row  d-flex align-items-stretch">
                    <div className="col-lg-6 faq-item">
                        <h4>世界のコーシャ認証団体数は？</h4>
                        <p>
                            世界には何百という（個人単位のものを含めると）コーシャ認証機関が存在します。
                        </p>
                        <h4>Super Kosher（スーパーコーシャ）って何？</h4>
                        <p>
                            コーシャ認定団体のビッグスリーと呼ばれる団体で、[OU(Orthodox
                            Union＝ニューヨーク本部)]、[OK(Organized
                            Kashrut＝ニューヨーク本部)]、[KLBD(Kosher London
                            Beth
                            Din＝ロンドン本部)]の3団体が質・規模ともにコーシャ認証で最も権威のある団体と言われています。いずれの認証も世界的に認められています。
                        </p>
                    </div>

                    <div className="col-lg-6 faq-item">
                        <h4>
                            なぜSuper
                            Kosherの団体の認証を取ったほうがいいのですか？
                        </h4>
                        <p>
                            ハラルの世界でもそうですが、乱立する認証団体により、コーシャ認証の基準・考えが歪んできています。その中でも古くから伝統とルールを守り、清潔、安全、衛生的というコーシャの概念を常に追求しているSuper
                            Kosherの団体のみが世界の消費者の信頼のマークとなっています。イスラエルなどでは国として認証団体の届け制度が有り、権威有る団体の認証マークのみがコーシャとして認められています。
                        </p>
                        <p>
                            OU,KLBD等の団体にコーシャ認証に携わるラビ(宗教指導者)は化学、薬品、食品などの各分野でのエキスパートです。またこれらの団体では膨大な原材料データをもとに何百人というスタッフが世界中で活躍しています。
                            最も注意スべき点は、これら3団体の相田ではお互いを認め合い、お互いの団体が認証した原材料は自らのものと同等であるとしている点です。言い換えれば、これら3団体以外が認証した原材料はこれらの団体でコーシャとして認められないことが多々あります。
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
