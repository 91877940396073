const Footer = () => {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 footer-info">
                            <h3>Yama Mizrach</h3>
                            <p>
                                〒980-0804 仙台市青葉区大町1丁目1-8 <br />
                                第三青葉ビル4F
                                <br />
                                <br />
                                <strong>Phone:</strong> +81 022-221-3568
                                <br />
                                <strong>Email:</strong> info@yamamizrach.com
                                <br />
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li>
                                    <i className="bx bx-chevron-right"></i>{' '}
                                    <a href="#hero">Home</a>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>{' '}
                                    <a href="#about">Yama Mizrachについて</a>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>{' '}
                                    <a href="#kosher">コーシャとは</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <ul>
                                <li>
                                    <i className="bx bx-chevron-right"></i>{' '}
                                    <a href="#companies">主な認定企</a>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>{' '}
                                    <a href="#acquisition">取得方法</a>
                                </li>
                                <li style={{ marginLeft: '2em' }}>
                                    <i className="bx bx-chevron-right"></i>
                                    <a href="acquisition.html">
                                        取得詳細と書類
                                    </a>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>{' '}
                                    <a href="#contact">お問い合わせ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
