// in src/Dashboard.js
import * as React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const Dashboard = () => (
    <Card>
        <CardHeader title="Welcome to the administration Page" />
        <CardContent>
            Choose which information process in the left bar.
        </CardContent>
    </Card>
);
export default Dashboard;
